import {
  createRouter,
  createWebHistory
} from 'vue-router'
import ErrorPage from '../views/error-page/Main.vue'

const routes = [{
    path: '/',
    redirect: 'dashboard',
    component: () => import('../layouts/dashboard-layout/Main.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('../views/dashboard/Main.vue'),
      },
      {
        path: 'projects',
        name: 'projects',
        component: () => import('../views/project/Main.vue'),
      },
      {
        path: 'dashboard-clone',
        name: 'dashboard-clone',
        component: () => import('../views/task-management/board-clone/Main.vue')
      },
      {
        path: 'tasks/:taskId',
        name: 'task.view',
        component: () => import('../views/task-management/task/Main.vue'),
      },
      {
        path: 'leaves/applications',
        name: 'leaves.applications',
        component: () => import('../views/leaves/Main.vue'),
      },
      {
        path: 'leaves/categories',
        name: 'leaves.categories',
        component: () => import('../views/leave-category/Main.vue'),
      },
      {
        path: 'outreach/sites',
        name: 'outreach.sites',
        component: () => import('../views/outreach/sites/Main.vue'),
      },
      {
        path: 'outreach/backlinks',
        name: 'outreach.backlinks',
        component: () => import('../views/outreach/backlinks/Main.vue'),
      },
      {
        path: 'outreach/invoices',
        name: 'outreach.invoices',
        component: () => import('../views/outreach/invoices/Main.vue'),
      },
      {
        path: 'hr/employee',
        name: 'hr.employee',
        component: () => import('../views/hr/employee/Main.vue'),
      },
      {
        path: 'hr/add-employee',
        name: 'hr.add-employee',
        component: () => import('../views/hr/employee/CreateEmployee.vue'),
      },
      {
        path: 'hr/edit-employee/:employeeId',
        name: 'hr.edit-employee',
        component: () => import('../views/hr/employee/EditEmployee.vue'),
      },
      {
        path: 'hr/view-employee/:employeeId',
        name: 'hr.view-employee',
        component: () => import('../views/hr/employee/ViewEmployee.vue'),
      },
      {
        path: 'hr/role',
        name: 'hr.role',
        component: () => import('../views/hr/role/Main.vue'),
      },
      // {
      //   path: 'hr/add-role',
      //   name: 'hr.add-role',
      //   component: () => import('../views/hr/role/CreateRole.vue'),
      // },
      {
        path: 'hr/designation',
        name: 'hr.designation',
        component: () => import('../views/hr/designation/Main.vue'),
      },
      {
        path: 'hr/department',
        name: 'hr.department',
        component: () => import('../views/hr/department/Main.vue'),
      },
      {
        path: 'hr/department/:departmentId',
        name: 'hr.department.show',
        component: () => import('../views/hr/department/DepartmentMembers.vue'),
      },
      {
        path: 'contacts/add-client',
        name: 'contacts.add-client',
        component: () => import('../views/outreach/sites/Main.vue'),
      },
      {
        path: 'user-profile',
        name: 'profile',
        component: () => import('../views/profiles/Main.vue'),
      },
      {
        path: 'settings',
        name: 'settings',
        component: () => import('@/views/settings/Main.vue'),
      }
    ]
  },
  {
    path: '/projects',
    component: () => import('../layouts/task-management-layout/Main.vue'),
    children: [
      {
        path: ':projectKey/roadmap',
        name: 'roadmap',
        component: () => import('../views/task-management/roadmap/Main.vue'),
        children: [],
      },
      {
        path: ':projectKey/backlog',
        name: 'backlog',
        component: () => import('../views/task-management/backlog/Main.vue'),
        props: true
      },
      {
        path: ':projectKey/backlog/tasks/:taskId',
        name: 'backlog.tasks.view',
        component: () => import('../views/task-management/task/BacklogTaskView.vue'),
      },
      {
        path: ':projectKey/backlog/tasks/:taskId/edit',
        name: 'backlog.tasks.edit',
        component: () => import('../views/task-management/task/BacklogTaskEdit.vue'),
      },
      {
        path: ':projectKey/board',
        name: 'board',
        component: () => import('../views/task-management/board/Main.vue'),
      },
      {
        path: ':projectKey/epics/:epicId',
        name: 'epic',
        component: () => import('../views/task-management/epic/Main.vue'),
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/Main.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    component: ErrorPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || {
      left: 0,
      top: 0
    }
  }
})

export default router
