const state = () => {
  return {
    comments: []
  }
}

const getters = {
  comments: state => state.comments,
}

const actions = {
  async storeComments({
    state
  }, data) {
    state.comments = data
  },

  async storeComment({
    state
  }, data) {
    state.comments.unshift(data)
  },

  async deleteComment({
    state
  }, data) {
    let index = state.comments.findIndex((comment) => comment.id == data.commentId);
    state.comments.splice(index, 1)
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions
}
