const state = () => {
  return {
    workFlows: []
  }
}

const getters = {
  workFlows: state => state.workFlows,
}

const mutations = {
  // ADD ALL WORKFLOWS IN WORKFLOWS LIST FIRST TIME
  SET_WORKFLOWS(state, workFlows) {
    state.workFlows = workFlows
  },
}

const actions = {
  async storeWorkFlows({commit}, data) {
    await commit('SET_WORKFLOWS', data);
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
