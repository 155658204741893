import axios from "axios";

let token = localStorage.getItem('token')

const Api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Authorization: `Bearer ${token}`
  }
});

export default Api;
