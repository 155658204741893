const state = () => {
  return {
    categories: []
  }
}

const getters = {
  categories: state => state.categories,
}

const mutations = {
  // ADD ALL CATEGORIES IN CATEGORIES LIST FIRST TIME
  ADD_CATEGORIES(state, categories) {
    state.categories = categories
  },

  // NEW CREATED PRIORTY PUSH INTO PREVIOUS PRIORTIES
  ADD_CATEGORY(state, category) {
    state.categories.push(category)
  },

  SET_CATEGORY(state, category) {
    state.categories.push(category)
  },
}

const actions = {
  async addCategories({
    commit
  }, data) {
    await commit('ADD_CATEGORIES', data);
  },

  async addCategory({
    commit
  }, data) {
    await commit('ADD_CATEGORY', data);
  },

  async storeCategory({
    commit
  }, data) {
    await commit('SET_CATEGORY', data);
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
