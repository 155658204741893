<template>
      <div v-if="errors && errors[field]" :class="['text-theme-24 mt-2']">
          {{ errors[field][0] }}
      </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  props:['errors','field'],
})
</script>


