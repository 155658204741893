const state = () => {
  return {
    headerText: null,
    bodyText: null
  }
}

const getters = {
  headerText: state => state.headerText,
  bodyText: state => state.bodyText,
}

const mutations = {
  SET_ALERT_TEXT(state, {headerText, bodyText}) {
    state.headerText = headerText
    state.bodyText = bodyText
  },
}

const actions = {
  async setAlertText({commit}, {headerText, bodyText}) {
     await commit('SET_ALERT_TEXT', {headerText, bodyText});
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
