import authRoute from "@/router/backend/auth";
import Api from "@/setting/axios";

export default {
  namespaced: true,
  state: () => ({
    loggedIn: false,
    user: {}
  }),
  getters: {

  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    SET_LOGGEDIN(state, loggedIn) {
      state.loggedIn = loggedIn
    }
  },
  actions: {
    async login({
      commit
    }, data) {
      //
    }
  },

}
