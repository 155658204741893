import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import globalComponents from './global-components'
import utils from './utils'
import './libs'
import Toast from "vue-toastification";
import Datepicker from 'vue3-date-time-picker';
import VCalendar from 'v-calendar';

const options = {
  timeout: 2000
};
import './assets/sass/app.scss'

const app = createApp(App)
  .use(store)
  .use(router)
  .use(Toast,options)
  .use(VCalendar, {})
  .component('Datepicker', Datepicker)

  globalComponents(app)
  utils(app)

app.mount('#app')
