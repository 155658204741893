import { defineAsyncComponent } from 'vue'
import Highlight from './highlight/Main.vue'
import Chart from './chart/Main.vue'
import Tippy from './tippy/Main.vue'
import TippyContent from './tippy-content/Main.vue'
import LoadingIcon from './loading-icon/Main.vue'
import FormRadio from './utils/FormRadio.vue'
import FormInput from './utils/FormInput.vue'
import FormLabel from './utils/FormLabel.vue'
import FormButton from './utils/FormButton.vue'
import ValidationMessage from './utils/ValidationMessage.vue'
import * as featherIcons from '@zhuowenli/vue-feather-icons'

export default app => {
  app.component('Chart', Chart)
  app.component('Highlight', Highlight)
  app.component('Tippy', Tippy)
  app.component('TippyContent', TippyContent)
  app.component('LoadingIcon', LoadingIcon)
  app.component('FormInput', FormInput)
  app.component('FormRadio', FormRadio)
  app.component('FormTextarea', defineAsyncComponent(() => import('./utils/FormTextarea.vue')))
  app.component('FormLabel', FormLabel)
  app.component('FormButton', FormButton)
  app.component('ValidationMessage', ValidationMessage)
  app.component('SlideOver',  defineAsyncComponent(() => import('./utils/SlideOver.vue')))
  app.component('Modal',  defineAsyncComponent(() => import('./utils/Modal.vue')))
  app.component('Alert',  defineAsyncComponent(() => import('./utils/Alert.vue')))
  app.component('Skeleton',  defineAsyncComponent(() => import('./utils/Skeleton.vue')))
  app.component('Flex',  defineAsyncComponent(() => import('./utils/Flex.vue')))
  app.component('Multiselect',  defineAsyncComponent(() => import('vue-multiselect')))
  app.component('FormSelect', defineAsyncComponent(() => import('./utils/FormSelect.vue')))
  app.component('FormCheckBox', defineAsyncComponent(() => import('./utils/FormCheckBox.vue')))
  app.component('FormGroupCheckBox', defineAsyncComponent(() => import('./utils/FormGroupCheckBox.vue')))
  app.component('DonutChart', defineAsyncComponent(() => import('./utils/DonutChart.vue')))
  app.component('DropdownSelect', defineAsyncComponent(() => import('./utils/DropdownSelect.vue')))
  app.component('Switch', defineAsyncComponent(() => import('./utils/Switch.vue')))

  for (const [key, icon] of Object.entries(featherIcons)) {
    icon.props.size.default = '24'
    app.component(key, icon)
  }
}
