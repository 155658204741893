
const getSprintIndex = (sprints,sprintId) => {
  return sprints.findIndex((sprint) => sprint.id == sprintId);
}

const getTaskIndex = (tasks,taskId) => {
  return tasks.findIndex((task) => task.id == taskId);
}
const state = () => {
  return {
    sprints: [],
    backlog: []
  }
}

const getters = {
  sprints: state => state.sprints,
  backlog: state => state.backlog
}

const actions = {
  //Store newly created data
  async storeSprint({ state }, data) {
    state.sprints.push(data)
  },

  //Load and store all data
  async storeSprints({ state }, data) {
    state.sprints = data.data
    state.backlog = data.backlog
  },

   //Replace updated data
   async updateSprint({ state }, data) {
    let index = state.sprints.findIndex((sprint) => sprint.id == data.id);
    state.sprints[index] = data;
  },

  // update task sprint data
  async updateTaskSprint({ state }, data) {

    let currentSprintId     = data.task['sprint_id'];
    let task                = data.task;
    let targetSprintId      = data.sprintId;

    // remove task from previous sprint

    let sprintIndex = getSprintIndex(state.sprints,currentSprintId)
    let sprint = state.sprints[sprintIndex];

    if (!sprint)
    {
      let taskIndex = getTaskIndex(state.backlog,task.id);
      state.backlog.splice(taskIndex, 1)
    }
    else
    {
      let taskIndex = getTaskIndex(sprint.tasks,task.id);
      sprint.tasks.splice(taskIndex, 1)
    }

    // task added current sprint

    sprintIndex = getSprintIndex(state.sprints,targetSprintId)
    sprint = state.sprints[sprintIndex];

    // change task sprint id
    task.sprint_id = targetSprintId

    if (!sprint)
      state.backlog.push(task)
    else
      sprint.tasks.push(task)

  },

  // updated task epic data
  async updateTaskEpic({ state }, data) {
    let sprintId = data.sprintId;
    let taskId   = data.taskId;
    let epic     = data.epic;


    let index = state.sprints.findIndex((sprint) => sprint.id == sprintId);
    let sprint = state.sprints[index];

    if (!sprint)
    {
      index = state.backlog.findIndex((task) => task.id == taskId);
      state.backlog[index].epic_id = epic.id ? epic.id : null;
      state.backlog[index].epic = epic;
    }
    else
    {
      index = sprint.tasks.findIndex((task) => task.id == taskId);
      sprint.tasks[index].epic_id = epic.id ? epic.id : null
      sprint.tasks[index].epic = epic
    }
  },

  //Remove deleted data
  async deleteSprint({ state }, sprintId) {
    let index = state.sprints.findIndex((sprint) => sprint.id == sprintId);
    let tasks = [...state.sprints[index].tasks];
     tasks.map(task => task['sprint_id'] = null);
     state.sprints.splice(index, 1)
     state.backlog = state.backlog.concat(tasks)
  },

  //sprint status update
  async sprintStatusUpdate({state,dispatch }, object) {
    let sprintIndex = getSprintIndex(state.sprints,object.sprintId)
    let sprint = state.sprints[sprintIndex];

    if(object.status == 'complete')
      state.sprints.splice(sprintIndex,1);
    else
      sprint.status = object.status;
  },

  //Add created task under the correspondant sprint
  async storeTask({state}, data) {
    let sprintIndex = getSprintIndex(state.sprints,data.sprint_id)
    let sprint = state.sprints[sprintIndex];

    if (!sprint)
      state.backlog.push(data);
    else
      sprint.tasks.push(data);
  },

  //Remove the task from the sprints
  async sprintTaskDelete({ state }, task) {

    let index = state.sprints.findIndex((sprint) => sprint.id == task.sprint_id);
    let sprint = state.sprints[index];

    if (!sprint)
    {
      index = state.backlog.findIndex((t) => t.id == task.id);
      state.backlog.splice(index, 1)
    }
    else
    {
      index = sprint.tasks.findIndex((t) => t.id == task.id);
      sprint.tasks.splice(index, 1)
    }
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions
}
