const state = () => {
  return {
    project: null,
    projects: null,
  }
}

const getters = {
  projects: state => state.projects,
}


const mutations = {
  ADD_PROJECTS(state, projects) {
    state.projects = projects
  },
}

const actions = {
 async addProjects({ commit }, projects) {
    await commit('ADD_PROJECTS', projects)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
