const state = () => {
  return {
    priorities: []
  }
}

const getters = {
  priorities: state => state.priorities,
}

const mutations = {
  // ADD ALL PRIORIES IN PRIORIES LIST FIRST TIME
  ADD_PRIORITIES(state, priorities) {
    state.priorities = priorities
  },

  // NEW CREATED PRIORTY PUSH INTO PREVIOUS PRIORTIES
  ADD_PRIORITY(state, priority) {
    state.priorities.push(priority)
  },

  SET_PRIORITY(state, priority) {
    state.priorities.push(priority)
  },
}

const actions = {
  async addPriorities({
    commit
  }, data) {
    await commit('ADD_PRIORITIES', data);
  },

  async addPriority({
    commit
  }, data) {
    await commit('ADD_PRIORITY', data);
  },

  async storePriority({
    commit
  }, data) {
    await commit('SET_PRIORITY', data);
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
