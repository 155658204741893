const state = () => {
  return {
    epics: [],
    epic: [],
    epicTasks:[]
  }
}

const getters = {
  epics: state => state.epics,
  epicTasks: state => state.epicTasks,
}

const mutations = {
  // ADD ALL EPICS IN EPICS LIST FIRST TIME
  ADD_EPICS(state, epics) {
    state.epics = epics
  },

  // NEW CREATED EPIC PUSH INTO PREVIOUS EPICS
  ADD_EPIC(state, epic) {
    state.epics.push(epic)
  },

  SET_EPIC(state, epic) {
    state.epics.push(epic)
  },
}

const actions = {
  async addEpics({commit},data) {
    await commit('ADD_EPICS',data);
  },

  async addEpic({commit},data) {
    await commit('ADD_EPIC',data);
  },

  async storeEpic({ commit },data) {
     await commit('SET_EPIC', data);
  },

  async storeEpicTasks({ state },tasks) {
    state.epicTasks = tasks
  },

  async addEpicTasks({ state },tasks) {
    state.epicTasks.push(tasks)
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
