const state = () => {
  return {
    roadmapLoadedBefore: false,
    tasks: [],
    boardTasks:[],
    taskChildTasks:[],
    sprintTaskEvent:[],
    taskSprinteventPlacement:null,
    task:null,
  }
}

const getters = {
  tasks: state => state.tasks,
  boardTasks: state => state.boardTasks,
  taskChildTasks: state => state.taskChildTasks,
  roadmapLoadedBefore: state => state.roadmapLoadedBefore,
  sprintTaskEvent: state => state.sprintTaskEvent,
  taskSprinteventPlacement: state => state.taskSprinteventPlacement
}

const mutations = {

}

const actions = {
  //Store sprint task event for track event is finish
  async storeSprintTaskEvent({state}, event) {
    if(event)
     state.sprintTaskEvent.push(event)
    else
    state.sprintTaskEvent = []
  },


  //Store newly created data
  async storeTask({state}, data) {
    state.task = data
  },

  //Load and store all data
  async storeTasks({ state}, data) {
    state.tasks = data
  },

  //Replace updated data
  async updateTask({state}, data) {
    let index = state.tasks.findIndex((task) => task.id == data.id);
    state.tasks[index] = data;
  },

  //Load and store all board task data
  async storeBoardTasks({state}, data) {
    state.boardTasks = data
  },

    //Load and store all board task data
  async updateBoardTasks({state}, task) {

    let boardIndex = state.boardTasks.findIndex((board) => board.id == task['workflow_id']);
    let taskIndex = state.boardTasks[boardIndex].tasks.findIndex((t) => t.id == task['id']);
    if(taskIndex >= 0 )
      state.boardTasks[boardIndex].tasks[taskIndex] = task;
  },

    // board delete task
  async boardTaskDelete({ state }, task) {
    // board index find
    let boardIndex = state.boardTasks.findIndex((board) => board.id == task['workflow_id']);
    //  task index find under board
    let taskIndex = state.boardTasks[boardIndex].tasks.findIndex((t) => t.id == task['id']);
    if(taskIndex >= 0)
    state.boardTasks[boardIndex].tasks.splice(taskIndex, 1)
  },



    //Load and store board modal task data
  async addChildTasks({state}, task) {
   if(state.taskChildTasks.length)
   {
      let taskIndex = state.taskChildTasks.findIndex((t) => t.id == task['id']);
      if(taskIndex >= 0)
        state.taskChildTasks[taskIndex] = task;
      else
      state.taskChildTasks.push(task)
    }
    else
    state.taskChildTasks.push(task)
  },

  async addChildTask({state}, task) {
    let taskIndex = state.taskChildTasks.findIndex((t) => t.id == task.parent_id);
    state.taskChildTasks[taskIndex].tasks.push(task);
  },

  // delete task
  async deleteTask({ state }, id) {
    let index = state.tasks.findIndex((task) => task.id == id);
    state.tasks.splice(index, 1)
  },

  //Remove deleted data
  async roadmapLoadedBefore({ state }) {
    state.roadmapLoadedBefore = true
  },

   // tract event is happend where
  async storeTaskSprintEventPlacement({state}, name) {
    state.taskSprinteventPlacement = name
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
