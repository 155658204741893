import Route from './route';

class userRoute extends Route
{
   routeList()
   {
    const routes = [
      {
        path: 'login',
        name: 'login',
      },
     ]

     return routes;
  }
}

export default new userRoute();


