import {
  createStore
} from 'vuex'
import main from './main'
import alert from './alert'
import sideMenu from './side-menu'
import settingMenu from './setting-menu'
import user from './modules/user'
import project from './modules/project'
import epic from './modules/epic'
import sprint from './modules/sprint'
import task from './modules/task'
import priority from './modules/priority'
import category from './modules/category'
import workflow from './modules/workflow'
import comment from './modules/comment'

const store = createStore({
  modules: {
    main,
    sideMenu,
    settingMenu,
    user,
    project,
    epic,
    sprint,
    task,
    alert,
    priority,
    comment,
    category,
    workflow
  }
})

export function useStore() {
  return store
}

export default store
