<template>
  <div class="flex">
    <div class="form-check mr-3 mt-2" v-for="option in options" :key="option">
      <label class="form-check-label" :for="option">
      <input :checked="modelValue == option ? true : false " :value="option" @change="$emit('update:modelValue',$event.target.value)" :name="name" :id="option" class="form-check-input" type="radio"/>
      {{ $h.capitalize(option) }} 
      </label>
    </div>
  </div>
</template>

<script>
 import { defineComponent } from 'vue'
 export default defineComponent({
  props:{
      options: Array,
      name:{
       type: String,
       default : 'radio'
      },
      modelValue:[String, Boolean]
  },
  changedatepicker(){
   
  }


})

</script>

