<template>
    <label class="form-label">{{ label }}</label>
</template>

<script>
    export default {
        props:{
            label:String
        },
    }
</script>

<style scoped>

</style>
