const state = () => {
  return {
      dashboardMenu: [
      {
        icon: 'HomeIcon',
        pageName: 'dashboard',
        title: 'Dashboard'
      },
      'devider',
      {
        icon: 'FramerIcon',
        pageName: 'projects',
        title: 'Projects'
      },
      {
        icon: 'ColumnsIcon',
        pageName: 'dashboard-clone',
        title: 'Board'
      },

      {
        icon: 'CrosshairIcon',
        pageName: 'leaves.applications',
        title: 'Leaves'
      },
      {
        icon: 'ExternalLinkIcon',
        title: 'Outreach',
        subMenu: [{
            pageName: 'outreach.sites',
            title: 'Sites'
          },
          {
            pageName: 'outreach.backlinks',
            title: 'Backlinks'
          },
          {
            pageName: 'outreach.invoices',
            title: 'Invoices'
          }
        ]
      },
      {
        icon: 'UsersIcon',
        title: 'HR',
        subMenu: [
          {
            pageName: 'hr.employee',
            title: 'Employee'
          },
          {
            pageName: 'hr.role',
            title: 'Role'
          },
          {
            pageName: 'hr.department',
            title: 'Department'
          },
          {
            pageName: 'hr.designation',
            title: 'Designation'
          },

        ]
      },
      {
        icon: 'SettingsIcon',
        pageName: 'settings',
        title: 'Settings'
      },
      'devider',

    ],
    taskMenu: [{
      pageName: 'dashboard',
        title: 'Dashboard'
      },
      {
        icon: 'FramerIcon',
        pageName: 'roadmap',
        title: 'Roadmap'
      },
      {
        icon: 'FramerIcon',
        pageName: 'backlog',
        title: 'Backlog'
      },
      {
        icon: 'CalendarIcon',
        pageName: 'board',
        title: 'Board'
      },
      'devider',
    ]
  }
}

// getters
const getters = {
  dashboardMenu: state => state.dashboardMenu,
  taskMenu: state => state.taskMenu
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,

}
