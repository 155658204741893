export default class Route
{
  route(routeName,object=null)
  {
    let routeList = this.routeList();
    let url = routeList.find(route => route.name === routeName)?.path

    if(!url)
      throw {message: 'Invalid Url',statusCode:404};

    if(object)
    {
      for(const param in object['params'])
      url = url.replace(`:${param}`,object['params'][param])

      if(object['query'] && object['query'] != null)
      {
        const queryString = new URLSearchParams(object['query']).toString();
        url = url.concat('?'+queryString)
      }
    }

    return url;
  }
}



