<template>
    <button class="btn btn-primary mr-1 mb-5" :class="[btnClass,classes]">
      <span class="flex" v-if="label">
        {{ eventName }}
        <LoadingIcon v-if="loading && !normalButton" icon="oval" color="white" class="w-4 h-4 ml-2" />
      </span>
      <span v-else>
        <slot/>
      </span>
    </button>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
export default defineComponent({
    name: "Button",
    props: {
        classes: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        actionLabel:{
          type: String,
          default: null
        },
        normalButton: {
            type: Boolean,
            default: true
        }
    },
    setup(props) {
        const loading = ref(false)

        const startLoading = () => {
            loading.value = true;
        }

        const stopLoading = () => {
            loading.value   = false;
        }

       const eventName =  computed(()=>{
           return  loading.value ? props.actionLabel ??  props.label  :  props.label;
        })

        const btnClass =  computed(()=>{
             return {
                "cursor-not-allowed": loading.value == true && !props.normalButton,
            };
        })

        return {
          loading,
          startLoading,
          stopLoading,
          btnClass,
          eventName
        }
    }
  })

</script>


