<template>
   <div class="input-group" v-if="inputGroup" >
      <div class="input-group-text">{{ label }}</div>
      <input
        ref="inputRef"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        class="form-control w-full"
        :class="classes"
        :type="type"
        :placeholder="placeholder"
        :disabled="disabled"
      />
  </div>
  <input v-else ref="inputRef" :disabled="disabled" :value="modelValue" @input="$emit('update:modelValue',$event.target.value)" class="form-control w-full" :class="classes" :type="type" :placeholder="placeholder">
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
export default defineComponent({
  props: {
    placeholder: {
      type: String,
      default: 'Enter Text'
    },
    classes: {
      type: String,
      default: ''
    },
    focus: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    inputGroup: {
      type: Boolean,
      default: false
    },
    label: String,
    modelValue: [String, Number],
    disabled:{
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const inputRef = ref('')
    onMounted(() => {
      if (props.focus) inputRef.value.focus()
    })
    return {
      inputRef
    }
  }
})
</script>

<style scoped>
</style>
