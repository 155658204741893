import _ from 'lodash'
import dayjs from 'dayjs'

const fakers = {
  fakeUsers() {
    const users = [
      { name: 'Johnny Depp', gender: 'male' },
      { name: 'Al Pacino', gender: 'male' },
      { name: 'Robert De Niro', gender: 'male' },
      { name: 'Kevin Spacey', gender: 'male' },
      { name: 'Denzel Washington', gender: 'male' },
    ]
    return _.sampleSize(users, 3).map(user => {
      return {
        name: user.name,
        gender: user.gender,
        email: _.toLower(_.replace(user.name, / /g, '') + '@viserx.com')
      }
    })
  },

  fakeImages() {
    const images = []
      images[images.length] = '200x200.jpg'
    return _.sampleSize(images, 10)
  },
}

const fakerData = []
for (let i = 0; i < 10; i++) {
  fakerData[fakerData.length] = {
    users: fakers.fakeUsers(),
    images: fakers.fakeImages(),
  }
}

const install = app => {
  app.config.globalProperties.$f = () => {
    return fakerData
  }
}

export { install as default, fakerData as faker }
