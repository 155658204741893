const state = () => {
  return {
      menuItems: [
      {
        icon: '-',
        component: 'Company',
        title: 'Company',
        name: 'company',
        arrow:false
      },
      {
        icon: '-',
        component: 'Outreach',
        title: 'Outreach',
        name: 'outreach',
        arrow:false
      },
      {
        icon: '-',
        title: 'Notification',
        name: 'notification',
        redirect: 'email',
        arrow:false,
        subMenu: [
        {
          icon: '-',
          component: 'Email',
          title: 'Email',
          name: 'email',
        },
        {
          icon: '-',
          component: 'Slack',
          title: 'Slack',
          name: 'slack',
        },
      ]
     },
    ],
  }
}

// getters
const getters = {
  menuItems: state => state.menuItems,
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,

}
